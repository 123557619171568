import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./app";

async function init() {
  const root = createRoot(document.getElementById("root"));
  const searchParams = new URLSearchParams(window.location.search);
  const name = searchParams.get('name') || 'web';
  const room = searchParams.get('room') || 'quickstart-room';
  const getTokenUrl = new URL('/getToken', window.location.origin);
  getTokenUrl.searchParams.set('name', name);
  getTokenUrl.searchParams.set('room', room);
  const token = await fetch(getTokenUrl).then((res) => res.text());
  root.render(
    <StrictMode>
      <App
        serverUrl='wss://dasloop-video-s4ma6zlh.livekit.cloud'
        token={token}
      />
    </StrictMode>
  );
}

init();
